/* eslint-disable no-loop-func */
import beautify from 'js-beautify';
import {
  camelCase,
} from 'change-case';
import helpers from '../../../helpers';

export default {
  jsonOutput: (state) => (numberOfRows) => {
    const jsonArr = [];
    if (state.model.fields) {
      for (let index = 0; index < numberOfRows; index += 1) {
        const newItem = {};
        state.model.fields.forEach((field) => {
          const val = helpers.methods.generateFaker(field.fakerType, field.fakerGenerator);
          newItem[camelCase(field.name)] = val;
        });
        jsonArr.push(newItem);
      }
    }
    let result = JSON.stringify(jsonArr);
    result = beautify(result);
    return result;
  },
  csvOutput: (state) => (numberOfRows) => {
    let result = '';
    if (state.model.fields) {
      // create headers
      state.model.fields.forEach((field) => {
        result += `${field.name}, `;
      });
      result = result.substring(0, result.length - 2);
      result += '<br />';
      for (let index = 0; index < numberOfRows; index += 1) {
        // create headers
        state.model.fields.forEach((field) => {
          const val = helpers.methods.generateFaker(field.fakerType, field.fakerGenerator);
          result += `${val}, `;
        });
        result = result.substring(0, result.length - 2);
        result += '<br />';
      }
    }
    return result;
  },
  locale: (state) => {
    const locale = { en: {}, af: {} };
    locale.en[state.model.name] = {};
    locale.af[state.model.name] = {};
    const enFields = {};
    const afFields = {};
    // eslint-disable-next-line no-unused-expressions
    state.model?.fields?.forEach((field) => {
      enFields[camelCase(field.name)] = field.label;
      afFields[camelCase(field.name)] = field.labelAf;
    });
    locale.en[state.model.name][`Form${[state.model.name]}`] = enFields;
    locale.af[state.model.name][`Form${[state.model.name]}`] = afFields;
    locale.en[state.model.name][`List${[state.model.name]}`] = enFields;
    locale.af[state.model.name][`List${[state.model.name]}`] = afFields;
    return locale;
  },
};
