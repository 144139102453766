<template>
  <v-card>
    <v-card-title>
      <span class="headline">Field</span>
    </v-card-title>
    <v-card-text>
      <v-text-field
        name="name"
        label="Name of Field"
        v-model="formItem.name"
        single-line
        data-cy="name"
        @input="nameChanged"
      />
      <v-text-field
        name="label"
        label="Label (EN)"
        v-model="formItem.label"
        single-line
        data-cy="label"
      />
      <v-text-field
        name="labelAf"
        label="Label (AF)"
        v-model="formItem.labelAf"
        single-line
        data-cy="label-af"
      />
      <v-select
        :items="controlTypes"
        v-model="formItem.controlType"
        label="Control Type"
        data-cy="controlType"
      />
      <v-select
        :items="dataTypes"
        v-model="formItem.dataType"
        label="Data Type"
        data-cy="dataType"
      />
      <v-select
        ref="fakerTypes"
        :items="fakerTypes"
        v-model="formItem.fakerType"
        label="Faker Type"
        data-cy="fakerType"
      />
      <v-select
        ref="fakerGenerators"
        v-show="fakerGenerators"
        :items="fakerGenerators"
        v-model="formItem.fakerGenerator"
        label="Faker Generator"
        data-cy="fakerGenerator"
      />
      <v-card
        class="mx-auto"
        v-show="formItem.fakerGenerator"
      >
        <v-card-text>
          <textarea v-html="preview" />
        </v-card-text>
        <v-card-actions>
          <v-btn data-cy="buttonRefresh" icon color="primary" @click="generate">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn data-cy="buttonCancel" color="default" text @click="$emit('cancel')">Cancel</v-btn>
      <v-btn data-cy="buttonSave" color="success" text @click="save">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import faker from 'faker';
import { capitalCase } from 'change-case';
import { mapActions } from 'vuex';

import helpers from '../helpers';

import fakerJson from './faker.json';

export default {
  mixins: [helpers],
  data() {
    return {
      defaultItem: {
        id: faker.random.uuid(),
        name: '',
        type: '',
        fakerType: undefined,
        fakerGenerator: undefined,
      },
      formItem: { ...this.defaultItem },
      fakerJson,
      mode: 'new',
      preview: '',
    };
  },
  computed: {
    fakerTypes() {
      return this.fakerJson.map((f) => f.name);
    },
    controlTypes() {
      return ['Checkbox', 'Select', 'Textbox', 'Image', 'Custom'];
    },
    dataTypes() {
      return ['int', 'double', 'Guid', 'string', 'byte[]', 'bool', 'DateTimeOffset', 'DateTime'];
    },
    fakerGenerators() {
      const { fakerType } = this.formItem;
      const res = this.fakerJson.find((f) => f.name === fakerType);
      if (!res) {
        return undefined;
      }
      return res.generators;
    },
  },
  created() {
    this.$root.$on('add', () => {
      this.resetForm();
      this.mode = 'add';
    });
    this.$root.$on('edit', (item) => {
      this.formItem = item;
      this.mode = 'update';
    });
  },
  destroyed() {
    this.$root.$off('add');
    this.$root.$off('edit');
  },
  methods: {
    ...mapActions('generator', ['addField']),
    generate() {
      const { fakerType, fakerGenerator } = this.formItem;
      this.preview = this.generateFaker(fakerType, fakerGenerator);
    },
    nameChanged(name) {
      this.formItem.label = capitalCase(name);
      this.formItem.labelAf = capitalCase(name);
      this.formItem.controlType = 'Textbox';
      this.formItem.dataType = 'string';
      this.formItem.fakerType = 'random';
      this.formItem.fakerGenerator = 'word';
    },
    resetForm() {
      this.preview = '';
      this.formItem = {};
      this.formItem = { ...this.defaultItem };
    },
    save() {
      if (this.mode === 'update') {
        this.$emit('update', this.formItem);
      } else {
        this.$emit('save', this.formItem);
      }
    },
  },
};
</script>
<style scoped>
textarea {
  display: block;
  width: 100%;
}
</style>
