<template>
  <v-data-table
    :headers="headers"
    :items="items"
    class="elevation-1"
    item-key="name"
  >
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="$emit('edit', (item))"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="$emit('delete', (item))"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      headers: [
        { text: 'Name of Field', value: 'name' },
        { text: 'Control Type', value: 'controlType' },
        { text: 'Label (EN)', value: 'label' },
        { text: 'Label (AF)', value: 'labelAf' },
        { text: 'Faker Type', value: 'fakerType' },
        { text: 'Faker Generator', value: 'fakerGenerator' },
        { text: '', value: 'actions' },
      ],
    };
  },
  computed: {
    ...mapState('generator', ['model']),
    items() {
      return this.model.fields;
    },
  },
};
</script>
