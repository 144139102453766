export default {
  model: {
    name: '',
    moduleName: '',
    testName: '',
    collectionName: '',
    namespace: '',
    objectName: '',
    prefix: '',
    controllerName: '',
    controllerType: '',
    fields: [],
  },
  numberOfRows: 10,
};
