<template>
  <v-card>
    <v-card-title primary-title>
      Test Data
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Rows to Generate"
            v-model="rows"
            single-line
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <h3>JSON</h3>
          <textarea v-text="jsonOutput(numberOfRows)" />
        </v-col>
        <v-col cols="4">
          <h3>CSV</h3>
          <div v-html="csvOutput(numberOfRows)" />
        </v-col>
        <v-col cols="4">
          <h3>Locale</h3>
          <textarea v-text="locale" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable no-loop-func */
import { mapGetters, mapMutations, mapState } from 'vuex';

import 'simple-syntax-highlighter/dist/sshpre.css';

import helpers from '../helpers';

export default {
  mixins: [helpers],
  name: 'GenerateJson',
  data() {
    return {
      rows: 10,
    };
  },
  computed: {
    ...mapGetters('generator', ['jsonOutput', 'csvOutput', 'locale']),
    ...mapState('generator', ['numberOfRows']),
  },
  watch: {
    rows() {
      this.SET_numberOfRows(this.rows);
    },
  },
  methods: {
    ...mapMutations('generator', ['SET_numberOfRows']),
  },
};
</script>

<style scoped>
textarea {
  display: block;
  width: 100%;
  height: 600px;
}
</style>
