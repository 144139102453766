export default {
  addModel(state, data) {
    const model = { ...data };
    state.fields = [];
    state.models.push(model);
  },
  selectedModel(state, value) {
    state.selectedModel = value;
  },
  SET_model(state, value) {
    state.model = value;
  },
  SET_fields(state, value) {
    state.fields = value;
  },
  SET_numberOfRows(state, value) {
    state.numberOfRows = value;
  },
};
