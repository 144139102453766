<template>
  <v-row>
    <v-col cols="3">
      <v-file-input
        class="mr-5"
        truncate-length="15"
        @change="fileChanged"
      ></v-file-input>
    </v-col>
    <v-col cols="9">
      <v-btn color="primary" @click="save" white--text right>
        <v-icon color="white" class="mr-2">mdi-file</v-icon>
        Save
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { saveAs } from 'file-saver';

export default {
  computed: {
    ...mapState('generator', ['model']),
    ...mapState('generator', ['numberOfRows']),
    ...mapGetters('generator', ['jsonOutput', 'csvOutput', 'locale']),
  },
  methods: {
    ...mapMutations('generator', ['SET_model']),
    fileChanged(file) {
      const fr = new FileReader();
      const self = this;
      fr.onload = (e) => {
        // e.target.result should contain the text
        console.log(e.target.result);
        self.SET_model(JSON.parse(e.target.result));
      };
      fr.readAsText(file);
    },
    save() {
      const obj = { ...this.model };
      obj.testData = JSON.parse(this.jsonOutput(this.numberOfRows));
      obj.locale = this.locale;
      console.log(obj);
      const blob = new Blob([JSON.stringify(obj)], { type: 'application/json;charset=utf-8' });
      saveAs(blob, this.model?.name ?? 'export.json');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
