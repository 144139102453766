import Vue from 'vue';
import axios from 'axios';
import vJsonEditor from 'v-jsoneditor';
import VueCodeHighlight from 'vue-code-highlight';
import 'vue-code-highlight/themes/window.css';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.use(vJsonEditor);
Vue.use(VueCodeHighlight);

// Setup API
axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY;
axios.defaults.baseURL = process.env.VUE_APP_APIURL;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
