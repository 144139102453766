<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-jsoneditor v-model="localModel" :options="{ mode: 'code' }" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title primary-title>
            Model
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  label="Namespace"
                  v-model="localModel.namespace" />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Model Name"
                  @input="nameChanged"
                  v-model="localModel.name" />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Module Name"
                  v-model="localModel.moduleName" />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Collection Name"
                  v-model="localModel.collectionName" />
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="controllerTypes"
                  v-model="localModel.controllerType"
                  label="Controller Type"
                ></v-select>
              </v-col>
            </v-row>
            <v-btn
              color="success"
              class="mb-10"
              @click="add"
              data-cy="buttonAdd"
            >Add Field</v-btn>
            <v-dialog v-model="dialog" persistent max-width="600px">
              <FormFields @cancel="dialog = false" @save="save" @update="dialog = false" />
            </v-dialog>
            <TableFields @edit="edit" @delete="remove" />
            <h4 class="mt-10">Module</h4>
            <p>{{ moduleText }}</p>
            <p>{{ dataContextText }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { camelCase } from 'change-case';
import FormFields from './FormFields.vue';
import TableFields from './TableFields.vue';

export default {
  name: 'FormModel',
  data() {
    return {
      controllerTypes: [
        'Aurora OData Controller',
        'Aurora Org OData Controller',
        'User Item API Controller',
        'Org Item API Controller',
      ],
      dialog: false,
      localModel: {},
    };
  },
  components: {
    FormFields,
    TableFields,
  },
  computed: {
    ...mapState('generator', ['model']),
    moduleText() {
      return `builder.EntitySet<Data.Entities.${this.model.name}>("${camelCase(this.model.collectionName)}");`;
    },
    dataContextText() {
      return `public DbSet<Entities.${this.model.collectionName}> ${camelCase(this.model.name)} { get; set; }`;
    },
  },
  watch: {
    localModel() {
      this.SET_model(this.localModel);
    },
    model() {
      this.localModel = this.model;
    },
  },
  mounted() {
    this.localModel = this.model;
  },
  methods: {
    ...mapActions('generator', ['addField', 'removeField']),
    ...mapMutations('generator', ['SET_model']),
    add() {
      this.dialog = true;
      this.$root.$emit('add');
    },
    edit(item) {
      this.dialog = true;
      this.$root.$emit('edit', item);
    },
    nameChanged(name) {
      if (this.model.moduleName.length === 0
        || this.model.moduleName.length === name.length - 1) {
        this.model.moduleName = name;
      }
      if (this.model.testName.length === 0
        || this.model.testName.length === name.length - 1) {
        this.model.testName = name;
      }
      if (this.model.controllerName.length === 0
        || this.model.controllerName.length === name.length - 1) {
        this.model.controllerName = name;
      }
      if (this.model.objectName.length === 0
        || this.model.objectName.length === name.length - 1) {
        this.model.objectName = name;
      }
      if (this.model.collectionName.length === 0
        || this.model.collectionName.length === name.length - 1) {
        this.model.collectionName = name;
      }
    },
    remove(item) {
      this.dialog = false;
      this.removeField(item);
    },
    save(item) {
      this.dialog = false;
      this.addField(item);
    },
    update() {
      this.dialog = false;
    },
  },
};
</script>
