export default {
  addField({ commit, state }, field) {
    const { model } = state;
    model.fields.push(field);
    commit('SET_model', model);
  },
  removeField({ commit, state }, field) {
    console.debug(field);
    const { model } = state;
    model.fields = model.fields.filter((f) => f.name !== field.name);
    commit('SET_model', model);
  },
  addModel({ commit }, field) {
    commit('addModel', field);
  },
  selectModel({ commit }, data) {
    commit('selectedModel', data);
  },
};
