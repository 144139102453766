<template>
  <v-app>
    <v-app-bar
      app
      color="black"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Code HQ Logo"
          class="shrink mr-2"
          contain
          src="https://www.grinsens.com/wp-content/uploads/2020/07/code-HQ-large.png"
          transition="scale-transition"
          width="80"
        />
      </div>

      <v-spacer></v-spacer>

      <span class="mr-2">Mock API Generator</span>
    </v-app-bar>

    <v-main>
      <v-container>
        <LoadSave />
        <FormModel />
        <ClientOutput />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ClientOutput from './components/ClientOutput.vue';
import FormModel from './components/FormModel.vue';
import LoadSave from './components/LoadSave.vue';

export default {
  name: 'App',

  components: {
    ClientOutput,
    FormModel,
    LoadSave,
  },

  data: () => ({
    //
  }),
};
</script>
